import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const supportApi = createApi({
  reducerPath: "support",
  baseQuery: createCustomFetchBaseQuery(),
  endpoints: (builder) => ({
    // GET ALL CONTACT MESSAGES
    getAllContactMessages: builder.query({
      query: () => "admin/contact",
      transformResponse: (res) => res
    }),
    addContactMessage: builder.mutation({
      query: (body) => {
        return {
          url: `admin/contact`,
          method: 'post',
          body: { ...body },
        };
      },
    }),

    sendContactMessage: builder.mutation({
      query: (body) => (
        console.log('body', body), {
          url: `auth/contact-support`,
          method: 'post',
          body: body,
        }),
    }),

  }),
});

export const {
  useGetAllContactMessagesQuery, useAddContactMessageMutation, useSendContactMessageMutation
} = supportApi;
