import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  home: icon('ic_home'),
  messages: icon('ic_messages'),
  request: icon('ic_request'),
  community: icon('ic_community'),
  profile: icon('ic_profile'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Home'),
            path: paths.superAdminDashboard.root,
            icon: ICONS.home,
          },
          {
            title: t('Profile'),
            path: paths.superAdminDashboard.profile,
            icon: ICONS.profile,
          },
          {
            title: t('Messages'),
            path: paths.superAdminDashboard.messages,
            icon: ICONS.messages,
          },
          {
            title: t('Request'),
            path: paths.superAdminDashboard.request,
            icon: ICONS.request,
          },
          {
            title: t('Community'),
            path: paths.superAdminDashboard.community,
            icon: ICONS.community,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
