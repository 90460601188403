import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import MainLayout from 'src/layouts/main';
import SimpleLayout from 'src/layouts/simple';
import CompactLayout from 'src/layouts/compact';
// components
import { SplashScreen } from 'src/components/loading-screen';
import PrivacyPage from 'src/pages/dashboard/privacy';
import { PrivacyPolicyView, TacView } from 'src/sections/about/view';
import WhatWeOfferView from 'src/sections/about/view/what-we-offer-view';
// ----------------------------------------------------------------------

////
export const PopCircleHomePage = lazy(() => import('src/pages/pop-circle-lp'));
const Page500 = lazy(() => import('src/pages/500'));
const NoBusinessPage = lazy(() => import('src/pages/no-business'));
const Page403 = lazy(() => import('src/pages/403'));
const Page404 = lazy(() => import('src/pages/404'));
const PricingPage = lazy(() => import('src/pages/pricing'));
const PaymentPage = lazy(() => import('src/pages/payment'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const MaintenancePage = lazy(() => import('src/pages/maintenance'));
// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    element: (
      <MainLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </MainLayout>
    ),
    children: [
      { path: 'home', element: <PopCircleHomePage /> },
      { path: 'privacy-policy', element: <PrivacyPolicyView /> },
      { path: 'what-we-offer', element: <WhatWeOfferView /> },
      { path: 'tac', element: <TacView /> },
    ],
  },
  {
    element: (
      <SimpleLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </SimpleLayout>
    ),
    children: [
      { path: 'pricing', element: <PricingPage /> },
      { path: 'payment', element: <PaymentPage /> },
    ],
  },
  {
    element: (
      <CompactLayout>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </CompactLayout>
    ),
    children: [
      { path: 'coming-soon', element: <ComingSoonPage /> },
      { path: 'maintenance', element: <MaintenancePage /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: '403', element: <Page403 /> },
      { path: 'Failed', element: <NoBusinessPage /> },
    ],
  },
];
