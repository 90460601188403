// @mui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Badge, { badgeClasses } from '@mui/material/Badge';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// routes
import { paths } from 'src/routes/paths';
// components
import Logo from 'src/components/logo';
import Label from 'src/components/label';
//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//



import { SettingsButton, HeaderShadow, LoginButton, SignupButton, LanguagePopover } from '../_common';
import { minWidth, width } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSettingsContext } from 'src/components/settings';
import MaterialUISwitch from '../dashboard/SwitchThemeMode';


import { useEffect } from 'react';
import { useLocales } from 'src/locales';
import { useState } from 'react';
// ----------------------------------------------------------------------

export default function Header() {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const theme = useTheme();

  const mdUp = useResponsive('up', 'md');

  const { t, currentLang } = useLocales();
  const settings = useSettingsContext();


  const handleChange = () => {
    if (settings.themeMode === 'light') {
      settings.onUpdate('themeMode', 'dark');
    } else {
      settings.onUpdate('themeMode', 'light');
    }
  };


  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);


  const buttons = (direction, style) => {
    return (
      <Stack
        sx={{ ...style }}
        gap={2}
        alignItems={mdUp ? 'center' : 'start'}
        direction={direction}
      >
        {/* <FormGroup>
          <FormControlLabel
            onChange={handleChange}
            control={
              <MaterialUISwitch
                defaultChecked={settings.themeMode === 'dark' ? true : false}
                sx={{ m: 1 }}
              />
            }
          />
        </FormGroup> */}

        {/* <LanguagePopover /> */}

        <SignupButton sx={{ width: '8rem' }} />
      </Stack>
    );
  };
  return (
    <AppBar>

      <Toolbar
        disableGutters
        sx={{
          marginTop: "0",
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },

          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            // height: {
            //   md: HEADER.H_DESKTOP_OFFSET,
            // },
          }),
        }}
      >
        {isVisible && (
          <Box
            sx={{
              position: 'absolute',
              height: '5rem',
              width: '100vw',
              // backgroundColor: "#f44336",

              backdropFilter: 'blur(10px)',  // Adjust the blur intensity
              opacity: 0.5,  // Set the desired opacity
              zIndex: -1,
            }}
          />
        )}
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
          <Logo />

          <Box sx={{ flexGrow: 3 }} />

          {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}

          {mdUp && buttons('row')}
          {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} buttons={buttons} />}
        </Container>
      </Toolbar>

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}
