import { createApi } from "@reduxjs/toolkit/query/react";
import { createCustomFetchBaseQuery } from "../baseQuery";

export const dashboardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Visitors'],
  endpoints: (builder) => ({
    getDashboard: builder.query({
      query: () => `/dashboard`,
      transformResponse: (res) => res.body,
    }),

    getAllVisitors: builder.query({
      query: ({ year }) =>
        `/vistors/chart?year=${year}`,
      transformResponse: (res) => res.body,
      providesTags: ['Visitors'],
    }),
   
    getBusinessDashboard: builder.query({
      query: () =>
        `/business/analytics`,
      transformResponse: (res) => res.body,
    }),

    getVisitorsDashbaord: builder.query({
      query: () =>
        `/vistors/dashboard`,
      transformResponse: (res) => res,
      providesTags: ['Visitors'],
    }),

    addVisitors: builder.mutation({
      query: () => ({
        url: `/vistors`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      }),
      invalidatesTags: ['Visitors'],
    }),
  }),
});

export const {
  useGetDashboardQuery, useGetAllVisitorsQuery, useAddVisitorsMutation, useGetVisitorsDashbaordQuery, useGetBusinessDashboardQuery
} = dashboardApi;